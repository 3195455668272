import { get_Zero, op_Multiply, op_Addition, toByteArray, divRem, fromZero, equals, fromByteArray, fromInt32, toInt32 } from "./.fable/fable-library.3.0.0/BigInt.js";
import { StringBuilder__Append_244C7CD6, StringBuilder_$ctor } from "./.fable/fable-library.3.0.0/System.Text.js";
import { toString, FSharpRef } from "./.fable/fable-library.3.0.0/Types.js";
import { join } from "./.fable/fable-library.3.0.0/String.js";
import { fold, reverse } from "./.fable/fable-library.3.0.0/Seq.js";

export function toBase62(d) {
    const d_1 = toInt32(d) | 0;
    if (d_1 < 10) {
        return String.fromCharCode("0".charCodeAt(0) + d_1);
    }
    else if (d_1 < 36) {
        return String.fromCharCode(("A".charCodeAt(0) + d_1) - 10);
    }
    else if (d_1 < 62) {
        return String.fromCharCode(("a".charCodeAt(0) + d_1) - 36);
    }
    else {
        throw (new Error("Cannot encode digit \u0027d\u0027 to base 62."));
    }
}

export function fromBase62(c) {
    return fromInt32(((c >= "a") ? (c <= "z") : false) ? ((36 + c.charCodeAt(0)) - "a".charCodeAt(0)) : (((c >= "A") ? (c <= "Z") : false) ? ((10 + c.charCodeAt(0)) - "A".charCodeAt(0)) : (((c >= "0") ? (c <= "9") : false) ? (c.charCodeAt(0) - "0".charCodeAt(0)) : (() => {
        throw (new Error("Cannot decode char \u0027c\u0027 from base 62."));
    })())));
}

export function encode(input) {
    const b = fromByteArray(input);
    const sb = StringBuilder_$ctor();
    const convert = (current_mut, sb_1_mut) => {
        convert:
        while (true) {
            const current = current_mut, sb_1 = sb_1_mut;
            if (equals(current, fromZero())) {
            }
            else {
                let patternInput;
                let outArg = fromInt32(0);
                patternInput = [divRem(current, fromInt32(62), new FSharpRef(() => outArg, (v) => {
                    outArg = v;
                })), outArg];
                const value = StringBuilder__Append_244C7CD6(sb_1, toBase62(patternInput[1]));
                void value;
                current_mut = patternInput[0];
                sb_1_mut = sb_1;
                continue convert;
            }
            break;
        }
    };
    convert(b, sb);
    return join("", reverse(toString(sb).split("")));
}

export function decode(input) {
    return toByteArray(fold((current, c) => op_Addition(op_Multiply(current, fromInt32(62)), fromBase62(c)), get_Zero, input.split("")));
}

