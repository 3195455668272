import { rangeNumber, empty, singleton, collect, append, delay } from "./.fable/fable-library.3.0.0/Seq.js";
import { FSharpRef } from "./.fable/fable-library.3.0.0/Types.js";
import { initialize, map, concat } from "./.fable/fable-library.3.0.0/Array.js";

export function pow2(y) {
    return 1 << y;
}

export function FromBooleans(bools) {
    return Uint8Array.from(delay(() => {
        const b = new FSharpRef(0);
        return append(collect((i) => {
            const rem = (i % 8) | 0;
            return append(((rem === 0) ? (i !== 0) : false) ? append(singleton(b.contents), delay(() => {
                b.contents = 0;
                return empty();
            })) : empty(), delay(() => {
                if (bools[i]) {
                    b.contents = (b.contents + (pow2(rem) & 0xFF));
                    return empty();
                }
                else {
                    return empty();
                }
            }));
        }, rangeNumber(0, 1, bools.length - 1)), delay(() => singleton(b.contents)));
    }));
}

export function ToBooleans(bytes) {
    return concat(map((b) => initialize(8, (i) => ((pow2(i) & (~(~b))) > 0)), bytes));
}

