import { some } from "../fable-library.3.0.0/Option.js";

export function onError(text, ex) {
    console.error(some(text), ex);
}

export function toConsole(text, o) {
    console.log(some(text), o);
}

