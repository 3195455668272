import { createPieChart, createHorizontalBarChart, createBarChart } from "./createChart.js";
import { useReact_useEffect_Z101E1A95, React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useReact_useElementRef, useFeliz_React__React_useState_Static_1505, useReact_useRef_1505, React_functionComponent_Z4C5FE1BE } from "../Feliz.1.22.0/React.fs.js";
import { substring } from "../fable-library.3.0.0/String.js";
import { newGuid } from "../fable-library.3.0.0/Guid.js";
import { createObj } from "../fable-library.3.0.0/Util.js";
import { rangeNumber, getEnumerator, iterate } from "../fable-library.3.0.0/Seq.js";
import { toArray } from "../fable-library.3.0.0/Option.js";
import { ofSeq, tryFind } from "../fable-library.3.0.0/List.js";
import { createElement } from "react";

export const Interop_createBarChart = createBarChart;

export const Interop_createHorizontalBarChart = createHorizontalBarChart;

export const Interop_createPieChart = createPieChart;

const RoughViz_buildChart = React_functionComponent_Z4C5FE1BE("RoughViz", (props) => {
    let copyOfStruct;
    const elementId = useReact_useRef_1505("RoughViz" + substring((copyOfStruct = newGuid(), copyOfStruct), 10));
    const patternInput = useFeliz_React__React_useState_Static_1505(false);
    const rerendered = patternInput[0];
    const elementRef = useReact_useElementRef();
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(void 0);
    const parentWidth = patternInput_1[0];
    const observer = useReact_useRef_1505(new ResizeObserver(((entries) => {
        for (let idx = 0; idx <= (entries.length - 1); idx++) {
            patternInput_1[1](entries[idx].contentRect.width);
        }
    })));
    useReact_useEffect_Z5234A374(() => {
        const matchValue = elementRef.current;
        if (matchValue != null) {
            const element = matchValue;
            const emptyData = Object.assign({}, {
                labels: [],
                values: [],
            });
            const defaultConfig = Object.assign({}, {
                data: emptyData,
                element: "#" + elementId.current,
                width: element.clientWidth,
            });
            const config = Object.assign({}, defaultConfig, createObj(props.config));
            const matchValue_1 = props.chartType;
            switch (matchValue_1) {
                case "horizontalBar": {
                    Interop_createHorizontalBarChart(config);
                    break;
                }
                case "pie": {
                    Interop_createPieChart(config);
                    break;
                }
                default: {
                    Interop_createBarChart(config);
                }
            }
            observer.current.observe(element);
        }
        return React_createDisposable_3A5B6456(() => {
            const matchValue_2 = elementRef.current;
            if (matchValue_2 != null) {
                const element_2 = matchValue_2;
                observer.current.unobserve(element_2);
                while (!(element_2.firstChild == null)) {
                    const value = element_2.removeChild(element_2.firstChild);
                    void value;
                }
            }
        });
    }, [rerendered, props]);
    useReact_useEffect_Z101E1A95(() => {
        iterate((_arg1) => {
            patternInput[1](!rerendered);
        }, toArray(parentWidth));
    }, [parentWidth]);
    useReact_useEffect_Z5234A374(() => {
        const matchValue_3 = elementRef.current;
        if (matchValue_3 != null) {
            const barClickedHandler = tryFind((tupledArg) => (tupledArg[0] === "barClicked"), props.config);
            if (barClickedHandler != null) {
                const key_1 = barClickedHandler[0];
                const barClicked = barClickedHandler[1];
                const bars = document.getElementsByClassName(elementId.current);
                const enumerator = getEnumerator(ofSeq(rangeNumber(0, 1, bars.length - 1)));
                try {
                    while (enumerator["System.Collections.IEnumerator.MoveNext"]()) {
                        const barIndex = enumerator["System.Collections.Generic.IEnumerator`1.get_Current"]() | 0;
                        (bars[barIndex]).addEventListener("click", (_arg2) => {
                            barClicked(barIndex);
                        });
                    }
                }
                finally {
                    enumerator.Dispose();
                }
            }
        }
        return React_createDisposable_3A5B6456(() => {
            const bars_1 = document.getElementsByClassName(elementId.current);
            const enumerator_1 = getEnumerator(ofSeq(rangeNumber(0, 1, bars_1.length - 1)));
            try {
                while (enumerator_1["System.Collections.IEnumerator.MoveNext"]()) {
                    const barIndex_1 = enumerator_1["System.Collections.Generic.IEnumerator`1.get_Current"]() | 0;
                    const value_2 = (bars_1[barIndex_1]).removeEventListener("click", (_arg3) => {
                    });
                    void undefined;
                }
            }
            finally {
                enumerator_1.Dispose();
            }
        });
    }, [rerendered, props]);
    return createElement("div", {
        id: elementId.current,
        ref: elementRef,
        style: {
            width: 100 + "%",
        },
    });
});

export function RoughViz_barChart(properties) {
    return RoughViz_buildChart({
        chartType: "bar",
        config: properties,
    });
}

export function RoughViz_horizontalBarChart(properties) {
    return RoughViz_buildChart({
        chartType: "horizontalBar",
        config: properties,
    });
}

export function RoughViz_pieChart(properties) {
    return RoughViz_buildChart({
        chartType: "pie",
        config: properties,
    });
}

