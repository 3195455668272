import { useReact_useEffect_Z101E1A95, React_createDisposable_3A5B6456, useReact_useEffect_Z5234A374, useFeliz_React__React_useState_Static_1505, useReact_useRef_1505 } from "./.fable/Feliz.1.22.0/React.fs.js";
import { createCanvas, newSeed } from "./RoughJS/Rough.fs.js";
import { numberHash, randomNext } from "./.fable/fable-library.3.0.0/Util.js";
import { rangeNumber, iterate } from "./.fable/fable-library.3.0.0/Seq.js";
import { toArray } from "./.fable/fable-library.3.0.0/Option.js";
import { createElement } from "react";
import { printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { PromiseBuilder__For_1565554B, PromiseBuilder__Delay_62FBFDE1, PromiseBuilder__Run_212F1D4B } from "./.fable/Fable.Promise.2.1.0/Promise.fs.js";
import { promise } from "./.fable/Fable.Promise.2.1.0/PromiseImpl.fs.js";
import * as color from "color";
import { ofArray, contains } from "./.fable/fable-library.3.0.0/List.js";
import { reactApi } from "./.fable/Feliz.1.22.0/Interop.fs.js";

export function RoughDivider() {
    const context = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(newSeed());
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(randomNext(0, 10000));
    const patternInput_2 = useFeliz_React__React_useState_Static_1505(false);
    const rerendered = patternInput_2[0];
    const patternInput_3 = useFeliz_React__React_useState_Static_1505(void 0);
    const parentWidth = patternInput_3[0];
    const observer = useReact_useRef_1505(new ResizeObserver(((entries) => {
        for (let idx = 0; idx <= (entries.length - 1); idx++) {
            patternInput_3[1](entries[idx].contentRect.width);
        }
    })));
    useReact_useEffect_Z5234A374(() => {
        const matchValue = context.current;
        if (matchValue != null) {
            const canvas = matchValue.canvas;
            const w = parseFloat((window.getComputedStyle(canvas.parentElement)).width);
            canvas.width = w;
            const c = createCanvas(canvas);
            const options = c.getDefaultOptions();
            options.stroke = "#FFFFFF";
            options.roughness = 2;
            options.seed = patternInput[0];
            const value = c.line(0, 15, w, 15, options);
            void value;
            observer.current.observe(canvas.parentElement);
        }
        return React_createDisposable_3A5B6456(() => {
            const matchValue_1 = context.current;
            if (matchValue_1 != null) {
                observer.current.unobserve(matchValue_1.canvas.parentElement);
            }
        });
    }, [rerendered]);
    useReact_useEffect_Z101E1A95(() => {
        iterate((_arg1) => {
            patternInput_2[1](!rerendered);
        }, toArray(parentWidth));
    }, [parentWidth]);
    return createElement("canvas", {
        key: toText(printf("divider-canvas-%d"))(patternInput_1[0]),
        ref: (x) => {
            const canvas_1 = x;
            if (!(canvas_1 == null)) {
                context.current = (canvas_1.getContext('2d'));
            }
        },
        style: {
            flexShrink: 0,
            flexGrow: 1,
        },
        height: 30,
    });
}

export function Avatar() {
    const playerColors = [["#C51111", "#7A0838"], ["#132ED1", "#09158E"], ["#117F2D", "#0A4D2E"], ["#ED54BA", "#AB2BAD"], ["#EF7D0D", "#B33E15"], ["#F5F557", "#C38823"], ["#3F474E", "#1E1F26"], ["#D6E0F0", "#8394BF"], ["#6B2FBB", "#3B177C"], ["#71491E", "#5E2615"], ["#38FEDC", "#24A8BE"], ["#50EF39", "#15A742"]];
    const canvasRef = useReact_useRef_1505(void 0);
    const imageRef = useReact_useRef_1505(void 0);
    const hatRef = useReact_useRef_1505(void 0);
    const skinRef = useReact_useRef_1505(void 0);
    const patternInput = useFeliz_React__React_useState_Static_1505(1);
    const hat = patternInput[0] | 0;
    const patternInput_1 = useFeliz_React__React_useState_Static_1505(5);
    const waitForLoad = (image) => PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => ((!image.complete) ? ((new Promise(((ok, er) => {
        image.onload = ((_arg1) => {
            ok();
        });
        image.onerror = ((e) => {
            er((() => {
                throw (new Error("Error loading image"));
            })());
        });
    }))).then((() => (Promise.resolve(undefined))))) : (Promise.resolve()))));
    useReact_useEffect_Z101E1A95(() => {
        const pr = PromiseBuilder__Run_212F1D4B(promise, PromiseBuilder__Delay_62FBFDE1(promise, () => {
            const matchValue = [canvasRef.current, imageRef.current, hatRef.current, skinRef.current];
            let pattern_matching_result, context, hatImage, image_1, skinImage;
            if (matchValue[0] != null) {
                if (matchValue[1] != null) {
                    if (matchValue[2] != null) {
                        if (matchValue[3] != null) {
                            pattern_matching_result = 0;
                            context = matchValue[0];
                            hatImage = matchValue[2];
                            image_1 = matchValue[1];
                            skinImage = matchValue[3];
                        }
                        else {
                            pattern_matching_result = 1;
                        }
                    }
                    else {
                        pattern_matching_result = 1;
                    }
                }
                else {
                    pattern_matching_result = 1;
                }
            }
            else {
                pattern_matching_result = 1;
            }
            switch (pattern_matching_result) {
                case 0: {
                    return (Promise.all([waitForLoad(image_1), waitForLoad(hatImage), waitForLoad(skinImage)])).then(((_arg2) => {
                        const canvas = context.canvas;
                        canvas.width = image_1.width;
                        canvas.height = image_1.height;
                        context.clearRect(0, 0, canvas.width, canvas.height);
                        context.drawImage(image_1, 0, 0);
                        const playerColorChoice = 4;
                        const data = context.getImageData(0, 0, image_1.width, image_1.height);
                        return PromiseBuilder__For_1565554B(promise, rangeNumber(0, 4, data.data.length - 1), (_arg3) => {
                            const i = _arg3 | 0;
                            const r = data.data[i];
                            const g = data.data[i + 1];
                            const b = data.data[i + 2];
                            if (((r !== 255) ? true : (g !== 255)) ? true : (b !== 255)) {
                                const pixelColor = (new color("#000000")).mix(new color(playerColors[playerColorChoice][1]), b / 255).mix(new color(playerColors[playerColorChoice][0]), r / 255).mix(new color("#9acad5"), g / 255);
                                const value_4 = pixelColor.red();
                                data.data[i] = (value_4 & 0xFF);
                                const value_5 = pixelColor.green();
                                data.data[i + 1] = (value_5 & 0xFF);
                                const value_6 = pixelColor.blue();
                                data.data[i + 2] = (value_6 & 0xFF);
                                return Promise.resolve();
                            }
                            else {
                                return Promise.resolve();
                            }
                        }).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                            context.putImageData(data, 0, 0);
                            return (contains(hat - 1, ofArray([38, 3, 5, 14, 28]), {
                                Equals: (x, y_1) => (x === y_1),
                                GetHashCode: numberHash,
                            }) ? (context.globalCompositeOperation = "destination-over", Promise.resolve()) : (Promise.resolve())).then(() => PromiseBuilder__Delay_62FBFDE1(promise, () => {
                                const hatY = 17 - (new Int32Array([45, 25, 50, 37, 33, 60, 70, 20, 27, 35, 41, 52, 35, 29, 40, 49, 34, 40, 25, 52, 55, 46, 41, 49, 46, 36, 44, 59, 44, 39, 30, 32, 37, 26, 61, 40, 43, 26, 50, 51, 37, 44, 30, 22, 40, 42, 8, 29, 32, 36, 28, 22, 39, 42, 24, 30, 47, 27, 52, 44, 26, 44, 48, 47, 42, 48, 50, 32, 44, 38, 56, 19, 27, 30, 42, 43, 60, 34, 10, 45, 50, 33, 13, 2, 40, 32, 32, 55, 22, 999, 26, 29, 43]))[hat - 1];
                                context.drawImage(hatImage, 0, (hatY > 0) ? 0 : (-hatY), hatImage.width, hatImage.height, ((canvas.width / 2) - (hatImage.width / 2)) + 2, Math.max(hatY, 0), hatImage.width, hatImage.height);
                                context.globalCompositeOperation = "source-over";
                                context.drawImage(skinImage, 25, 46);
                                return Promise.resolve();
                            }));
                        }));
                    }));
                }
                case 1: {
                    return Promise.resolve();
                }
            }
        }));
        pr.then();
    }, [hat]);
    const children = ofArray([createElement("img", {
        style: {
            display: "none",
        },
        src: "/img/avatar.png",
        ref: (x_1) => {
            const image_2 = x_1;
            if (!(image_2 == null)) {
                imageRef.current = image_2;
            }
        },
    }), createElement("img", {
        style: {
            display: "none",
        },
        src: toText(printf("/img/hats/%i.png"))(hat),
        ref: (x_2) => {
            const image_3 = x_2;
            if (!(image_3 == null)) {
                hatRef.current = image_3;
            }
        },
    }), createElement("img", {
        style: {
            display: "none",
        },
        src: toText(printf("/img/skins/%i.png"))(patternInput_1[0]),
        ref: (x_3) => {
            const image_4 = x_3;
            if (!(image_4 == null)) {
                skinRef.current = image_4;
            }
        },
    }), createElement("canvas", {
        ref: (x_4) => {
            const canvas_1 = x_4;
            if (!(canvas_1 == null)) {
                canvasRef.current = (canvas_1.getContext('2d'));
            }
        },
    }), createElement("button", {
        onClick: (_arg2_1) => {
            patternInput[1](hat + 1);
        },
        children: "Click here",
    })]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

