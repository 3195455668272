import { class_type } from "../fable-library.3.0.0/Reflection.js";
import { singleton, collect, delay } from "../fable-library.3.0.0/Seq.js";
import { defaultArg } from "../fable-library.3.0.0/Option.js";

export class pieChart {
    constructor() {
    }
}

export function pieChart$reflection() {
    return class_type("Feliz.RoughViz.pieChart", void 0, pieChart);
}

export function pieChart_data_527FDF20(points) {
    const labels = Array.from(delay(() => collect((matchValue) => singleton(matchValue[0]), points)));
    const values = Float64Array.from(delay(() => collect((matchValue_1) => singleton(matchValue_1[1]), points)));
    return ["data", Object.assign({}, {
        labels: labels,
        values: values,
    })];
}

export function pieChart_margin_Z22AC61C0(top, right, left, bottom) {
    return ["margin", {
        top: defaultArg(top, 50),
        right: defaultArg(right, 20),
        left: defaultArg(left, 100),
        bottom: defaultArg(bottom, 70),
    }];
}

