import { Record, Union } from "./.fable/fable-library.3.0.0/Types.js";
import { array_type, uint8_type, record_type, int32_type, union_type, string_type } from "./.fable/fable-library.3.0.0/Reflection.js";
import { oneOf, str, s, map } from "./.fable/Fable.Elmish.Browser.3.0.4/parser.fs.js";
import { singleton as singleton_1, ofSeq, mapIndexed, filter, empty, ofArray, collect } from "./.fable/fable-library.3.0.0/List.js";
import { some } from "./.fable/fable-library.3.0.0/Option.js";
import { Navigation_newUrl, Navigation_modifyUrl } from "./.fable/Fable.Elmish.Browser.3.0.4/navigation.fs.js";
import { Cmd_none, Cmd_OfFunc_result } from "./.fable/Fable.Elmish.3.0.0/cmd.fs.js";
import { fill, append } from "./.fable/fable-library.3.0.0/Array.js";
import { toInt32 } from "./.fable/fable-library.3.0.0/BitConverter.js";
import { compress, decompress } from "./StatsCompression.fs.js";
import { encode, decode } from "./Base62.fs.js";
import { join, printf, toText } from "./.fable/fable-library.3.0.0/String.js";
import { createElement } from "react";
import { reactApi } from "./.fable/Feliz.1.22.0/Interop.fs.js";
import { empty as empty_1, singleton, append as append_1, delay } from "./.fable/fable-library.3.0.0/Seq.js";
import { RoughDivider } from "./Components.fs.js";
import { RoughViz_pieChart } from "./.fable/Feliz.RoughViz.1.5.0/RoughViz.fs.js";
import { pieChart_data_527FDF20 } from "./.fable/Feliz.RoughViz.1.5.0/PieChart.fs.js";
import { createObj } from "./.fable/fable-library.3.0.0/Util.js";
import { Helpers_combineClasses } from "./.fable/Feliz.Bulma.2.4.0/ElementBuilders.fs.js";
import { awaitPromise, startImmediate } from "./.fable/fable-library.3.0.0/Async.js";
import { singleton as singleton_2 } from "./.fable/fable-library.3.0.0/AsyncBuilder.js";
import { rgba } from "./.fable/Feliz.1.22.0/Colors.fs.js";

export class Page extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["Home", "GameStats"];
    }
}

export function Page$reflection() {
    return union_type("App.Page", [], Page, () => [[], [["Item", string_type]]]);
}

export const pageParser = (() => {
    let parseBefore;
    const parsers = ofArray([map(new Page(0), s("")), map((arg0) => (new Page(1, arg0)), (parseBefore = s("stats"), (state_1) => collect(str, parseBefore(state_1))))]);
    return (state_3) => oneOf(parsers, state_3);
})();

export class State extends Record {
    constructor(BodiesReported, Emergencies, Tasks, AllTasks, SabotagesFixed, ImpostorKills, TimesKilled, TimesEjected, CrewmateStreak, TimesImpostor, TimesCrewmate, GamesStarted, GamesFinished, CrewmateVoteWins, CrewmateTaskWins, ImpostorVoteWins, ImpostorKillWins, ImpostorSabotageWins, Page) {
        super();
        this.BodiesReported = (BodiesReported | 0);
        this.Emergencies = (Emergencies | 0);
        this.Tasks = (Tasks | 0);
        this.AllTasks = (AllTasks | 0);
        this.SabotagesFixed = (SabotagesFixed | 0);
        this.ImpostorKills = (ImpostorKills | 0);
        this.TimesKilled = (TimesKilled | 0);
        this.TimesEjected = (TimesEjected | 0);
        this.CrewmateStreak = (CrewmateStreak | 0);
        this.TimesImpostor = (TimesImpostor | 0);
        this.TimesCrewmate = (TimesCrewmate | 0);
        this.GamesStarted = (GamesStarted | 0);
        this.GamesFinished = (GamesFinished | 0);
        this.CrewmateVoteWins = (CrewmateVoteWins | 0);
        this.CrewmateTaskWins = (CrewmateTaskWins | 0);
        this.ImpostorVoteWins = (ImpostorVoteWins | 0);
        this.ImpostorKillWins = (ImpostorKillWins | 0);
        this.ImpostorSabotageWins = (ImpostorSabotageWins | 0);
        this.Page = Page;
    }
}

export function State$reflection() {
    return record_type("App.State", [], State, () => [["BodiesReported", int32_type], ["Emergencies", int32_type], ["Tasks", int32_type], ["AllTasks", int32_type], ["SabotagesFixed", int32_type], ["ImpostorKills", int32_type], ["TimesKilled", int32_type], ["TimesEjected", int32_type], ["CrewmateStreak", int32_type], ["TimesImpostor", int32_type], ["TimesCrewmate", int32_type], ["GamesStarted", int32_type], ["GamesFinished", int32_type], ["CrewmateVoteWins", int32_type], ["CrewmateTaskWins", int32_type], ["ImpostorVoteWins", int32_type], ["ImpostorKillWins", int32_type], ["ImpostorSabotageWins", int32_type], ["Page", Page$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, ...fields) {
        super();
        this.tag = (tag | 0);
        this.fields = fields;
    }
    cases() {
        return ["StatsUploaded", "DisplayStats"];
    }
}

export function Msg$reflection() {
    return union_type("App.Msg", [], Msg, () => [[["Item", array_type(uint8_type)]], [["Item", string_type]]]);
}

export function toHash(_arg1) {
    if (_arg1.tag === 1) {
        return "#stats/" + _arg1.fields[0];
    }
    else {
        return "#";
    }
}

export function urlUpdate(result, model) {
    if (result == null) {
        console.error(some("Error parsing url"));
        return [model, Navigation_modifyUrl(toHash(model.Page))];
    }
    else if (result.tag === 1) {
        const encodedStats = result.fields[0];
        return [new State(model.BodiesReported, model.Emergencies, model.Tasks, model.AllTasks, model.SabotagesFixed, model.ImpostorKills, model.TimesKilled, model.TimesEjected, model.CrewmateStreak, model.TimesImpostor, model.TimesCrewmate, model.GamesStarted, model.GamesFinished, model.CrewmateVoteWins, model.CrewmateTaskWins, model.ImpostorVoteWins, model.ImpostorKillWins, model.ImpostorSabotageWins, new Page(1, encodedStats)), Cmd_OfFunc_result(new Msg(1, encodedStats))];
    }
    else {
        const page = result;
        return [new State(model.BodiesReported, model.Emergencies, model.Tasks, model.AllTasks, model.SabotagesFixed, model.ImpostorKills, model.TimesKilled, model.TimesEjected, model.CrewmateStreak, model.TimesImpostor, model.TimesCrewmate, model.GamesStarted, model.GamesFinished, model.CrewmateVoteWins, model.CrewmateTaskWins, model.ImpostorVoteWins, model.ImpostorKillWins, model.ImpostorSabotageWins, page), empty()];
    }
}

export function init(result) {
    return urlUpdate(result, new State(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, new Page(0)));
}

export function processGameBytes(b) {
    const b_1 = append(b, fill(new Uint8Array(4 - (b.length % 4)), 0, 4 - (b.length % 4), 0), Uint8Array);
    const bodiesReported = toInt32(b_1, 0);
    const emergencies = toInt32(b_1, 4);
    const tasks = toInt32(b_1, 8);
    const allTasks = toInt32(b_1, 12);
    const sabotagesFixed = toInt32(b_1, 16);
    const impostorKills = toInt32(b_1, 20);
    const timesKilled = toInt32(b_1, 24);
    const timesEjected = toInt32(b_1, 28);
    const crewmateStreak = toInt32(b_1, 32);
    const timesImpostor = toInt32(b_1, 36);
    const timesCrewmate = toInt32(b_1, 40);
    const gamesStarted = toInt32(b_1, 44);
    const gamesFinished = toInt32(b_1, 48);
    const crewmateVoteWins = toInt32(b_1, 52);
    const crewmateTaskWins = toInt32(b_1, 56);
    const impostorKillWins = toInt32(b_1, 60);
    const impostorVoteWins = toInt32(b_1, 64);
    return {
        AllTasks: allTasks,
        BodiesReported: bodiesReported,
        CrewmateStreak: crewmateStreak,
        CrewmateTaskWins: crewmateTaskWins,
        CrewmateVoteWins: crewmateVoteWins,
        Emergencies: emergencies,
        GamesFinished: gamesFinished,
        GamesStarted: gamesStarted,
        ImpostorKillWins: impostorKillWins,
        ImpostorKills: impostorKills,
        ImpostorSabotageWins: toInt32(b_1, 68),
        ImpostorVoteWins: impostorVoteWins,
        SabotagesFixed: sabotagesFixed,
        Tasks: tasks,
        TimesCrewmate: timesCrewmate,
        TimesEjected: timesEjected,
        TimesImpostor: timesImpostor,
        TimesKilled: timesKilled,
    };
}

export function update(msg, state) {
    if (msg.tag === 1) {
        const res = processGameBytes(decompress(decode(msg.fields[0])));
        return [new State(res.BodiesReported, res.Emergencies, res.Tasks, res.AllTasks, res.SabotagesFixed, res.ImpostorKills, res.TimesKilled, res.TimesEjected, res.CrewmateStreak, res.TimesImpostor, res.TimesCrewmate, res.GamesStarted, res.GamesFinished, res.CrewmateVoteWins, res.CrewmateTaskWins, res.ImpostorVoteWins, res.ImpostorKillWins, res.ImpostorSabotageWins, state.Page), Cmd_none()];
    }
    else {
        const encodedStats = encode(compress(msg.fields[0]));
        return [state, Navigation_newUrl(toText(printf("#stats/%s"))(encodedStats))];
    }
}

export function copyToClipboard() {
    const value = navigator.clipboard.writeText("%UserProfile%\\AppData\\LocalLow\\Innersloth\\Among Us\\playerStats2");
    void value;
}

export function render(state, dispatch) {
    const impostorTotalWins = ((state.ImpostorKillWins + state.ImpostorSabotageWins) + state.ImpostorVoteWins) | 0;
    const impostorTotalLoses = (state.TimesImpostor - impostorTotalWins) | 0;
    const impostorWinPercent = (impostorTotalWins / state.TimesImpostor) * 100;
    const crewmateWins = (state.CrewmateTaskWins + state.CrewmateVoteWins) | 0;
    const crewmateLoses = (state.TimesCrewmate - crewmateWins) | 0;
    const crewmateWinPercent = (crewmateWins / state.TimesCrewmate) * 100;
    const filterZeros = (data) => filter((tupledArg) => (tupledArg[1] !== 0), data);
    const impostorWinsBreakdown = filterZeros(ofArray([["Kill", state.ImpostorKillWins], ["Sabotage", state.ImpostorSabotageWins], ["Vote", state.ImpostorVoteWins]]));
    const impostorWinStats = filterZeros(ofArray([["Win", impostorTotalWins], ["Lose", impostorTotalLoses]]));
    const crewmateWinsBreakdown = filterZeros(ofArray([["Task", state.CrewmateTaskWins], ["Vote", state.CrewmateVoteWins]]));
    const crewmateWinStats = filterZeros(ofArray([["Win", crewmateWins], ["Lose", crewmateLoses]]));
    const kdRatio = state.ImpostorKills / state.TimesKilled;
    const kds = filterZeros(ofArray([["Kills", state.ImpostorKills], ["Deaths", state.TimesKilled]]));
    const percentageComplete = (state.GamesFinished / state.GamesStarted) * 100;
    const percentageTimesImpostor = (state.TimesImpostor / state.GamesStarted) * 100;
    const susFactor = (state.TimesEjected / state.TimesImpostor) * 5;
    const averageImpostorKills = state.ImpostorKills / state.TimesImpostor;
    const tableLegend = (data_1, colors) => {
        let children;
        return createElement("table", {
            style: {
                marginLeft: "auto",
                marginRight: "auto",
                fontFamily: "gaeguregular",
                fontSize: 2 + "rem",
                lineHeight: 1.2 + "em",
            },
            children: reactApi.Children.toArray([(children = mapIndexed((i, tupledArg_1) => createElement("tr", {
                style: {
                    color: colors[i % colors.length],
                },
                children: reactApi.Children.toArray([createElement("td", {
                    style: {
                        paddingRight: 20 + "px",
                    },
                    children: tupledArg_1[0],
                }), createElement("td", {
                    children: tupledArg_1[1],
                })]),
            }), data_1), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children)),
            }))]),
        });
    };
    const defaultChartColors = ["#FF7F50", "#87CEEB", "#66c2a5", "#D2B48C", "#8da0cb", "#e78ac3", "#a6d854", "#ffd92f", "#FF7F50", "#87CEEB", "#D2B48C", "#FFA500"];
    const impostorColors = ["#00FFFF", "#FF69B4", "#FFA500"];
    const crewmateColors = ["#8df580", "#87CEEB"];
    return createElement("div", {
        className: "app-container",
        children: reactApi.Children.toArray([createElement("div", {
            className: "app-content",
            children: reactApi.Children.toArray(Array.from(ofSeq(delay(() => append_1(singleton(createElement("div", {
                id: "stars",
            })), delay(() => append_1(singleton(createElement("div", {
                id: "stars2",
            })), delay(() => append_1(singleton(createElement("div", {
                id: "stars3",
            })), delay(() => append_1(singleton(createElement("div", {
                className: "hero is-white",
                children: reactApi.Children.toArray([createElement("div", {
                    className: "hero-body",
                    children: reactApi.Children.toArray([createElement("div", {
                        className: "container has-text-centered",
                        children: reactApi.Children.toArray([createElement("h1", {
                            className: "title",
                            style: {
                                fontSize: 3 + "rem",
                            },
                            children: reactApi.Children.toArray(["Among Us Stats"]),
                        })]),
                    })]),
                })]),
            })), delay(() => {
                let elms_9;
                return (state.Page.tag === 1) ? singleton((elms_9 = ofSeq(delay(() => ((state.GamesStarted > 0) ? append_1(singleton(createElement(RoughDivider, null)), delay(() => {
                    let twitterText, escapedUrl, twitterShareHref;
                    return append_1(singleton((twitterText = encodeURIComponent(toText(printf("My Among Us #susfactor is %.2f, check out the rest of my stats here!"))(susFactor)), (escapedUrl = encodeURIComponent(window.location.href), (twitterShareHref = toText(printf("https://twitter.com/intent/tweet/?text=%s\u0026url=%s"))(twitterText)(escapedUrl), createElement("div", {
                        children: reactApi.Children.toArray([createElement("div", {
                            children: reactApi.Children.toArray([createElement("h2", {
                                style: {
                                    fontFamily: "gaeguregular",
                                    fontSize: 3 + "rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                },
                                children: reactApi.Children.toArray([createElement("span", {
                                    children: "sus factor: ",
                                    ["data-tooltip"]: "(Times Ejected / Times Impostor) x 5",
                                }), createElement("span", {
                                    children: toText(printf("%.2f"))(susFactor),
                                    style: {
                                        color: "#FF69B4",
                                    },
                                })]),
                            })]),
                        }), createElement("div", {
                            children: reactApi.Children.toArray([createElement("h2", {
                                style: {
                                    fontFamily: "gaeguregular",
                                    fontSize: 3 + "rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                },
                                children: reactApi.Children.toArray([createElement("span", {
                                    children: "average impostor kills: ",
                                    ["data-tooltip"]: "Impostor Kills / Times Impostor",
                                }), createElement("span", {
                                    children: toText(printf("%.2f"))(averageImpostorKills),
                                    style: {
                                        color: "#FF7F50",
                                    },
                                })]),
                            })]),
                        }), createElement("div", {
                            style: {
                                textAlign: "center",
                            },
                            children: reactApi.Children.toArray([createElement("a", {
                                ["aria-label"]: "Share on Twitter",
                                className: "resp-sharing-button__link",
                                href: twitterShareHref,
                                rel: "noopener",
                                target: "_blank",
                                children: reactApi.Children.toArray([createElement("div", {
                                    className: join(" ", ["resp-sharing-button", "resp-sharing-button--twitter", "resp-sharing-button--large"]),
                                    children: reactApi.Children.toArray([createElement("div", {
                                        ["aria-hidden"]: true,
                                        className: join(" ", ["resp-sharing-button__icon", "resp-sharing-button__icon--solid"]),
                                        children: reactApi.Children.toArray([createElement("svg", {
                                            style: {
                                                width: 1 + "em",
                                                height: 1 + "em",
                                            },
                                            viewBox: (((((0 + " ") + 0) + " ") + 24) + " ") + 24,
                                            xmlns: "http://www.w3.org/2000/svg",
                                            children: reactApi.Children.toArray([createElement("path", {
                                                d: "M23.44 4.83c-.8.37-1.5.38-2.22.02.93-.56.98-.96 1.32-2.02-.88.52-1.86.9-2.9 1.1-.82-.88-2-1.43-3.3-1.43-2.5 0-4.55 2.04-4.55 4.54 0 .36.03.7.1 1.04-3.77-.2-7.12-2-9.36-4.75-.4.67-.6 1.45-.6 2.3 0 1.56.8 2.95 2 3.77-.74-.03-1.44-.23-2.05-.57v.06c0 2.2 1.56 4.03 3.64 4.44-.67.2-1.37.2-2.06.08.58 1.8 2.26 3.12 4.25 3.16C5.78 18.1 3.37 18.74 1 18.46c2 1.3 4.4 2.04 6.97 2.04 8.35 0 12.92-6.92 12.92-12.93 0-.2 0-.4-.02-.6.9-.63 1.96-1.22 2.56-2.14z",
                                            })]),
                                        })]),
                                    }), "Share on Twitter"]),
                                })]),
                            })]),
                        })]),
                    }))))), delay(() => append_1(singleton(createElement(RoughDivider, null)), delay(() => {
                        let elms_2, elms, elms_1;
                        return append_1(singleton((elms_2 = ofArray([(elms = ofArray([RoughViz_pieChart(ofArray([["colors", impostorColors], ["title", toText(printf("Impostor Wins - %.2f%%"))(impostorWinPercent)], pieChart_data_527FDF20(impostorWinStats), ["roughness", Math.abs(2)], ["height", 350], ["legend", false]])), tableLegend(impostorWinStats, impostorColors)]), createElement("div", {
                            className: "column",
                            children: reactApi.Children.toArray(Array.from(elms)),
                        })), (elms_1 = ofArray([RoughViz_pieChart(ofArray([["colors", crewmateColors], ["title", toText(printf("Crewmate Wins - %.2f%%"))(crewmateWinPercent)], pieChart_data_527FDF20(crewmateWinStats), ["roughness", Math.abs(2)], ["height", 350], ["legend", false]])), tableLegend(crewmateWinStats, crewmateColors)]), createElement("div", {
                            className: "column",
                            children: reactApi.Children.toArray(Array.from(elms_1)),
                        }))]), createElement("div", {
                            className: "columns",
                            children: reactApi.Children.toArray(Array.from(elms_2)),
                        }))), delay(() => append_1(singleton(createElement(RoughDivider, null)), delay(() => {
                            let elms_7, elms_5, elms_6;
                            return append_1(singleton((elms_7 = ofArray([(elms_5 = ofArray([RoughViz_pieChart(ofArray([["colors", impostorColors], ["title", "Impostor Wins Breakdown"], pieChart_data_527FDF20(impostorWinsBreakdown), ["roughness", Math.abs(2)], ["height", 350], ["legend", false]])), tableLegend(impostorWinsBreakdown, impostorColors)]), createElement("div", {
                                className: "column",
                                children: reactApi.Children.toArray(Array.from(elms_5)),
                            })), (elms_6 = ofArray([RoughViz_pieChart(ofArray([["colors", crewmateColors], ["title", "Crewmate Wins Breakdown"], pieChart_data_527FDF20(crewmateWinsBreakdown), ["roughness", Math.abs(2)], ["height", 350], ["legend", false]])), tableLegend(crewmateWinsBreakdown, crewmateColors)]), createElement("div", {
                                className: "column",
                                children: reactApi.Children.toArray(Array.from(elms_6)),
                            }))]), createElement("div", {
                                className: "columns",
                                children: reactApi.Children.toArray(Array.from(elms_7)),
                            }))), delay(() => append_1(singleton(createElement(RoughDivider, null)), delay(() => append_1(singleton(createElement("h2", {
                                style: {
                                    fontFamily: "gaeguregular",
                                    fontSize: 3 + "rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                },
                                children: reactApi.Children.toArray(["You have been an impostor ", createElement("span", {
                                    children: state.TimesImpostor,
                                    style: {
                                        color: "#FF69B4",
                                    },
                                }), " times of ", createElement("span", {
                                    children: state.GamesStarted,
                                    style: {
                                        color: "#00FFFF",
                                    },
                                }), " (", createElement("span", {
                                    children: toText(printf("%.2f%%"))(percentageTimesImpostor),
                                    style: {
                                        color: "#FF69B4",
                                    },
                                }), ")"]),
                            })), delay(() => append_1(singleton(createElement(RoughDivider, null)), delay(() => {
                                let elms_4, elms_3;
                                return append_1(singleton((elms_4 = singleton_1((elms_3 = ofArray([RoughViz_pieChart(ofArray([["title", toText(printf("Kills/Deaths Ratio - %.2f"))(kdRatio)], pieChart_data_527FDF20(kds), ["roughness", Math.abs(2)], ["height", 350], ["legend", false]])), tableLegend(kds, defaultChartColors)]), createElement("div", {
                                    className: "column",
                                    children: reactApi.Children.toArray(Array.from(elms_3)),
                                }))), createElement("div", {
                                    className: "columns",
                                    children: reactApi.Children.toArray(Array.from(elms_4)),
                                }))), delay(() => append_1(singleton(createElement(RoughDivider, null)), delay(() => append_1(singleton(createElement("h2", {
                                    style: {
                                        fontFamily: "gaeguregular",
                                        fontSize: 3 + "rem",
                                        fontWeight: "bold",
                                        textAlign: "center",
                                    },
                                    children: reactApi.Children.toArray(["You have finished ", createElement("span", {
                                        children: state.GamesFinished,
                                        style: {
                                            color: "#00FFFF",
                                        },
                                    }), " of ", createElement("span", {
                                        children: state.GamesStarted,
                                        style: {
                                            color: "#FFA500",
                                        },
                                    }), " games (", createElement("span", {
                                        children: toText(printf("%.2f%%"))(percentageComplete),
                                        style: {
                                            color: "#00FFFF",
                                        },
                                    }), ")"]),
                                })), delay(() => append_1(singleton(createElement(RoughDivider, null)), delay(() => {
                                    let children_10;
                                    return append_1(singleton((children_10 = ofArray([createElement("h2", {
                                        style: {
                                            fontFamily: "gaeguregular",
                                            fontSize: 3 + "rem",
                                            fontWeight: "bold",
                                            textAlign: "center",
                                        },
                                        children: "Your other stats:",
                                    }), tableLegend(ofArray([["Bodies Reported", state.BodiesReported], ["Emergencies Called", state.Emergencies], ["Tasks Completed", state.Tasks], ["All Tasks Completed", state.AllTasks], ["Sabotages Fixed", state.SabotagesFixed], ["Impostor Kills", state.ImpostorKills], ["Times Killed", state.TimesKilled], ["Times Ejected", state.TimesEjected], ["Crewmate Streak", state.CrewmateStreak]]), defaultChartColors)]), createElement("div", {
                                        children: reactApi.Children.toArray(Array.from(children_10)),
                                    }))), delay(() => singleton(createElement(RoughDivider, null))));
                                }))))))));
                            }))))))));
                        }))));
                    }))));
                })) : empty_1()))), createElement("div", {
                    className: "container",
                    children: reactApi.Children.toArray(Array.from(elms_9)),
                }))) : append_1(singleton(createElement("div", {
                    className: join(" ", ["container", "pb-6"]),
                    children: reactApi.Children.toArray([createElement("article", {
                        className: join(" ", ["message"]),
                        children: reactApi.Children.toArray([createElement("div", {
                            className: "message-body",
                            children: reactApi.Children.toArray(["This web app lets you view and share your Among Us stats from Windows, unfortunately loading from mobile is not supported", createElement("br", {}), "Your stats are stored in the following location: ", createElement("strong", {
                                children: ["%UserProfile%\\AppData\\LocalLow\\Innersloth\\Among Us\\playerStats2"],
                            }), createElement("br", {}), createElement("strong", {
                                children: ["Step 1: "],
                            }), "Click the left button to copy this path to your clipboard", createElement("br", {}), createElement("strong", {
                                children: ["Step 2: "],
                            }), "Click the right button, in the file selector paste in the location of the file and click select", createElement("br", {}), createElement("strong", {
                                children: ["Step 3: "],
                            }), "Profit"]),
                        })]),
                    })]),
                })), delay(() => {
                    let elms_8, props_67, props_70;
                    return singleton(createElement("div", {
                        className: join(" ", ["container"]),
                        children: reactApi.Children.toArray([(elms_8 = ofArray([(props_67 = ofArray([["style", {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }], ["children", reactApi.Children.toArray([createElement("button", {
                            className: "button is-boxed",
                            onClick: (_arg2) => {
                                copyToClipboard();
                            },
                            children: reactApi.Children.toArray([createElement("span", {
                                className: "icon",
                                style: {
                                    marginRight: 0.5 + "em",
                                },
                                children: reactApi.Children.toArray([createElement("i", {
                                    className: join(" ", ["fas", "fa-clone"]),
                                })]),
                            }), "Step 1: Copy stats file path"]),
                        }), createElement("img", {
                            style: {
                                paddingTop: 30 + "px",
                            },
                            src: "/img/aucyan.png",
                        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_67)))), (props_70 = ofArray([["style", {
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                        }], ["children", reactApi.Children.toArray([createElement("div", {
                            className: "file",
                            style: {
                                justifyContent: "center",
                            },
                            children: reactApi.Children.toArray([createElement("label", {
                                className: "file-label",
                                children: reactApi.Children.toArray([createElement("input", {
                                    className: "file-input",
                                    type: "file",
                                    name: "resume",
                                    onChange: (ev) => {
                                        const files = ev.target.files;
                                        if ((!(files == null)) ? (files.length > 0) : false) {
                                            const file = files.item(0);
                                            startImmediate(singleton_2.Delay(() => singleton_2.TryWith(singleton_2.Delay(() => singleton_2.Bind(awaitPromise(file.slice(1, 73).arrayBuffer()), (_arg1_1) => {
                                                dispatch(new Msg(0, new Uint8Array(_arg1_1)));
                                                return singleton_2.Zero();
                                            })), (_arg2_1) => {
                                                return singleton_2.Zero();
                                            })));
                                        }
                                    },
                                }), createElement("span", {
                                    className: "button",
                                    tabIndex: 0,
                                    children: reactApi.Children.toArray([createElement("span", {
                                        className: "file-icon",
                                        children: reactApi.Children.toArray([createElement("i", {
                                            className: "fas fa-upload",
                                        })]),
                                    }), createElement("span", {
                                        className: "file-label",
                                        children: "Step 2: Load stats file",
                                    })]),
                                })]),
                            })]),
                        }), createElement("img", {
                            style: {
                                paddingTop: 30 + "px",
                            },
                            src: "/img/auorange.png",
                        })])]]), createElement("div", createObj(Helpers_combineClasses("column", props_70))))]), createElement("div", {
                            className: "columns",
                            children: reactApi.Children.toArray(Array.from(elms_8)),
                        }))]),
                    }));
                }));
            })))))))))))),
        }), createElement("div", {
            style: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 124 + "px",
                height: 64 + "px",
                backgroundColor: "#FFFFFF",
                color: "#2f313d",
                borderRadius: 12 + "px",
                position: "fixed",
                right: 18 + "px",
                bottom: 18 + "px",
                boxShadow: (((((0 + "px ") + 4) + "px ") + 8) + "px ") + rgba(0, 0, 0, 0.4),
                zIndex: 9999,
                fontWeight: 600,
                transitionProperty: "all 0.2s ease 0s",
                border: ((((1 + "px") + " ") + "solid") + " ") + "#d2d2d2",
            },
            children: reactApi.Children.toArray([createElement("p", {
                children: reactApi.Children.toArray(["Built by ", createElement("a", {
                    children: "Stu",
                    href: "https://stu.dev",
                    style: {
                        color: "#18a9cd",
                    },
                })]),
            })]),
        })]),
    });
}

