import { ToBooleans, FromBooleans } from "./BitConverterExtensions.fs.js";
import { concat, map } from "./.fable/fable-library.3.0.0/Array.js";
import { rangeNumber, map as map_1 } from "./.fable/fable-library.3.0.0/Seq.js";

export function compress(input) {
    const nonEmptyByteMap = FromBooleans(map((b) => (b !== 0), input));
    return concat([new Uint8Array([input.length & 0xFF]), nonEmptyByteMap, input.filter((b_1) => (b_1 !== 0))], Uint8Array);
}

export function decompress(input) {
    const targetLength = (~(~input[0])) | 0;
    const mapLength = (~(~(targetLength / 8))) | 0;
    const nonEmptyBytes = ToBooleans(input.slice(1, mapLength + 1));
    let compressedByteIndex = 0;
    return Uint8Array.from(map_1((i) => {
        if (nonEmptyBytes[i]) {
            const b = input[(1 + mapLength) + compressedByteIndex];
            compressedByteIndex = (compressedByteIndex + 1);
            return b;
        }
        else {
            return 0;
        }
    }, rangeNumber(0, 1, targetLength - 1)));
}

